import * as React from "react";
import { Link } from "gatsby";
import '../styles/components/_footer.scss';

const Footer = () => (
<footer className="footer">
	<p></p>
	<div className="footer--flexbox">
		<div className="footer--flexbox--left">
			<p>
				<Link to="/bio/">Bio</Link><br />
				<Link to="/contact/">Contact</Link><br />
				<Link to="/presskit/">Press Kit</Link><br />
				<Link to="/support/">Support</Link><br />
				<Link to="/pay/">Payments</Link>
			</p>
			<p>
				<Link to="/events/">Shows</Link><br />
				<Link to="/merch/">Merch</Link><br />
				<Link to="/news/">News</Link><br />
				<Link to="/blog/">Blog</Link>
			</p>
			<p>
				<Link to="/music/">Music</Link><br />
				<Link to="/collab/">Collabs</Link><br />
				<Link to="/video/">Video</Link><br />
				<Link to="/hire/">Hire</Link>
			</p>
			<p>
				<Link to="/">Welcome</Link><br />
				<Link to="/now/">Now</Link>
			</p>
		</div>
	</div>
	<div className="footer--flexbox">
		<p className="footer--flexbox__bottom footer--flexbox--center">
			© 2021 <Link to="/devonly/" style={{textDecoration: 'none'}}>–</Link> {new Date().getFullYear()},
			Gatsby site by&nbsp;<a href="//www.cxelegance.com/">CXElegance</a>.
		</p>
	</div>
</footer>
);

export default Footer;
